.featured {
    padding-top: 32px;
}

.featured-seven {
    padding: 115px 0 50px;
}

.saaspik-icon-box-wrapper {
    margin-bottom: 30px;

    &.style-one {
        margin-top: 16px;

        .saaspik-icon-box-icon {
            margin-bottom: 40px;
            height: 150px;

        }

        .pixsass-icon-box-content {
            .pixsass-icon-box-title {
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
                margin-bottom: 0;

                a {
                    color: $color__primary;

                    &:hover {
                        color: $color__theme;
                    }
                }
            }
        }
    }

    .saaspik-icon-box-icon {
        margin-bottom: 32px;
    }

    .pixsass-icon-box-content {
        .pixsass-icon-box-title {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 20px;
            transition: all 0.3s ease-in-out;

            a {
                color: $color__primary;

                &:hover {
                    color: $color__theme;
                }
            }
        }

        p {
            margin: 0;
        }
    }

    &.style-two {
        margin-bottom: 88px;

        .pixsass-icon-box-content {
            .pixsass-icon-box-title {
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
                margin-bottom: 10px;
                

                a {
                    color: $color__primary;

                    &:hover {
                        color: $color__theme;
                    }
                }
            }
        }
    }

    &.style-three {
        border-radius: 6px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 40px 60px 0px rgba(79, 35, 35, 0.14);
        padding: 40px 50px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 22;
        align-items: center;

        .saaspik-icon-box-icon {
            width: 70px;
            margin-right: 30px;
            margin-bottom: 0;
        }

        .pixsass-icon-box-content {
            flex: 1;

            p {
                margin: 0;
            }
        }
    }

    &.style-four {
        border-radius: 4px;
        border: 1px solid #ece4e4;
        padding: 60px 40px 32px;
        transition: all 0.3s ease-in-out;
        position: relative;
        overflow: hidden;

        .layer {
            position: absolute;
            top: -100%;
            left: 0;
            width: 100%;
            z-index: -1;
            transition: all 0.6s ease-in-out;
        }

        .saaspik-icon-box-icon {
            margin-bottom: 44px;
            height: 80px;
        }

        .pixsass-icon-box-content {
            .pixsass-icon-box-title {
                font-size: 22px;
                font-weight: 500;
                margin-bottom: 25px;

                a {
                    color: $color__primary;

                    &:hover {
                        color: $color__theme;
                    }
                }
            }

            p {
                margin-bottom: 22px;
            }

            .more-btn {
                font-size: 35px;
                color: #bdb4b4;
                display: inline-block;

                &:hover {
                    color: $color__theme;
                }
            }
        }

        &:hover {
            box-shadow: 0px 40px 60px 0px rgba(79, 35, 35, 0.14);

            .layer {
                top: 0;
            }
        }
    }

    &.style-five {
        box-shadow: 0px 30px 60px 0px rgba(52, 27, 159, 0.1);
        padding: 45px 40px;
        background: #fff;

        .saaspik-icon-box-icon {
            height: 60px;
            width: 60px;
            line-height: 60px;
            text-align: center;
            border: 2px solid $color__theme-2;
            color: $color__theme-2;
            font-size: 22px;
            border-radius: 50%;
            transition: all 0.3s ease-in-out;
            margin-bottom: 42px;
        }

        .pixsass-icon-box-content {
            .pixsass-icon-box-title {
                margin-bottom: 15px;

                a {
                    &:hover {
                        color: $color__theme-2;
                    }
                }
            }

            p {
                margin-bottom: 35px;
            }

            .more-btn {
                color: $color__primary;
                font-weight: 500;

                i {
                    vertical-align: middle;
                    display: inline-block;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    color: $color__theme-2;

                    i {
                        padding-left: 3px;
                        color: $color__theme-2;
                    }
                }
            }
        }

        &:hover {
            .saaspik-icon-box-icon {
                box-shadow: 0px 20px 30px 0px rgba(75, 42, 222, 0.2);
                background: $color__theme-2;
                color: #fff;
            }
        }
    }

    &.style-six {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        flex-wrap: wrap;

        .saaspik-icon-box-icon {
            height: 70px;
            width: 70px;
            text-align: center;
            box-shadow: 0px 30px 40px 0px rgba(52, 27, 159, 0.1);
            padding: 15px;
            border-radius: 4px;
            transition: all 0.3s ease-in-out;
            margin-bottom: 0;
            margin-right: 30px;
            background: #fff;
        }

        .pixsass-icon-box-content {
            flex: 1;

            .pixsass-icon-box-title {
                margin-bottom: 15px;
                font-weight: 400;
                margin: 0;

                a {
                    &:hover {
                        color: $color__theme-2;
                    }
                }
            }

            p {
                margin-bottom: 35px;
            }

            .more-btn {
                color: $color__primary;
                font-weight: 500;

                i {
                    vertical-align: middle;
                    display: inline-block;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    color: $color__theme-2;

                    i {
                        padding-left: 3px;
                        color: $color__theme-2;
                    }
                }
            }
        }
    }

    &.style-seven  {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 90px;

        .saaspik-icon-box-icon {
            width: 60px;
            margin-right: 20px;
        }
        .saaspik-icon-box-content {
            flex: 2;
            .saaspik-icon-box-title {
                font-size: 20px;
                color: #081e39;
                font-weight: 600;
                margin-bottom: 13px;
                a {
                    color: #081e39;

                    &:hover {
                        color: $color__theme-2;
                    }
                }
            }

            p {
                color: #5e5b74;
                margin-bottom: 17px;
            }

            .read-more {
                color: #5e5b74;
                font-size: 16px;
                font-weight: 500;

                &:hover {
                    color: $color__theme-2;
                }
            }
        }
    }
}

.featured-six {
    padding: 110px 0 104px;
}

.featured-five {
    padding: 70px 0
}

.featured-two-service {
    .saaspik-icon-box-wrapper.style-two {
        .pixsass-icon-box-content {
            .pixsass-icon-box-title {
                a {
                    color: $color__primary;

                    &:hover {
                        color: $color__theme;
                    }
                }
            }
        }
    }
}

.featured-two {
    padding: 126px 0 28px;

    .section-title {
        margin-bottom: 80px;
    }
}

.featured-three {
    padding: 116px 0 120px;
    background: $background__bg;
    overflow: hidden;

    .scroll-circle {
        z-index: 1;
        left: -120px;
        bottom: -67%;
    }
}

.featured-four {
    padding-top: 400px;
}

.featured-two-same {
    padding-top: 144px;
    padding-bottom: 27px;

    .section-title {
        margin-bottom: 80px;
    }

    .saaspik-icon-box-wrapper.style-two {
        .pixsass-icon-box-content {
            .pixsass-icon-box-title {
                a {
                    color: $color__primary;

                    &:hover {
                        color: $color__theme-2;
                    }
                }
            }
        }
    }

    .scroll-circle {
        bottom: auto;
        top: -50%;
    }
}

.featured-four-ab {
    padding-top: 146px;
}

.featured-homethree {
    padding-top: 228px;
    padding-bottom: 27px;

    .section-title {
        margin-bottom: 80px !important;
    }
}


.down-bg {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
}

@media (max-width: 1200px) {
    .featured-homethree {
        padding-top: 118px;
    }

    .featured-six {
        padding-top: 50px;
    }
}

@media (max-width: 991px) {
    .featured-two {
        padding: 85px 0 35px;
    }

    .featured-two-same {
        padding-top: 74px;
        padding-bottom: 44px;
    }

    .featured {
        padding-top: 75px;
    }

    .featured-three {
        padding: 76px 0 80px;
    }

    .featured-homethree {
        padding-top: 0;
    }

    .saaspik-icon-box-wrapper.style-two {
        margin-bottom: 40px;
        text-align: center;
    }

    .featured-four {
        padding-top: 185px;
        padding-bottom: 40px;
    }

    .featured-homethree {
        padding-bottom: 34px;
    }

    .featured-four-ab {
        padding-top: 76px;
    }

    .featured-two-service {
        padding: 55px 0 43px !important;
    }

    .featured-two-same .scroll-circle {
        left: -20%;
    }

    .featured-three {
        .scroll-circle {
            z-index: 1;
            left: -15%;
            bottom: 20%;

            img {
                max-width: 450px;
            }
        }
    }

    .saaspik-icon-box-wrapper {
        &.style-one {
            text-align: center;
        }
    }

    .saaspik-icon-box-wrapper {
        .pixsass-icon-box-content {
            .pixsass-icon-box-title {
                a {
                    br {
                        display: none;
                    }
                }
            }
        }
    }


    .featured-six {
        padding-top: 0;
        padding-bottom: 38px;
    }  

    .featured-seven {
        padding: 75px 0 30px;
    }

    .saaspik-icon-box-wrapper {
        &.style-seven {
            margin-bottom: 50px;
        }
    }
}

@media (max-width: 768px) {

    .saaspik-icon-box-wrapper .pixsass-icon-box-content p {
        br {
            display: none;
        }
    }

    .featured-four {
        padding-top: 250px;
    }

    .featured-two-same .scroll-circle {
        left: -30%;
    }

    .featured-three {
        .scroll-circle {
            z-index: 1;
            left: -15%;
            bottom: 20%;

            img {
                max-width: 330px;
            }
        }
    }

    .feature-image-wrapper {
        margin-bottom: 40px;
    }

}

@media (max-width: 767px) {
    .featured-four {
        padding-top: 137px;
    }
}

@media (max-width: 576px) {
    .featured-four {
        padding-top: 76px;
    }

}

@media (max-width: 480px) {
    .featured-two-same .scroll-circle {
        left: -70%;
    }

    .saaspik-icon-box-wrapper {
        &.style-three {
            text-align: center;
            display: block;

            .saaspik-icon-box-icon {
                margin: 0 auto 30px;
            }
        }
    }


}