.pix-btn {
    padding: 8px 30px;
    background: $color__theme;
    border-radius: 30px;
    display: inline-block;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);

    &.submit-btn {
        border: 0;
        padding: 11px 35px;

        &:focus {
            outline: 0;
        }
    }

    &.btn-large {
        padding: 16px 44px;
        font-size: 16px;
    }

    &.plus-icon {
        padding: 8px 64px 8px 41px;
        position: relative;

        i {
            margin-left: 10px;
            font-size: 20px;
            display: inline-block;
            position: absolute;
            right: 32px;
            top: 11px;
        }
    }

    &.btn-big {
        padding: 16px 47px;
        font-size: 16px;
    }

    &.color-two {
        background: $color__theme-2;
        box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
    }

    &:hover,
    &:focus {
        color: #fff;
        box-shadow: none;
        outline: 0;
    }

    &.btn-outline {
        border: 1px solid $color__theme;
        background: transparent;
        color: $color__theme;
        box-shadow: none;

        &:hover {
            background: $color__theme;
            color: #fff;
            box-shadow: 0px 20px 30px 0px rgba(176, 70, 70, 0.3);
        }

    }

    &.btn-outline-two {
        border: 1px solid $color__theme-2;
        background: transparent;
        color: $color__theme-2;
        box-shadow: none;

        &:hover {
            background: $color__theme-2;
            color: #fff;
            box-shadow: 0px 20px 30px 0px rgba(90, 70, 176, 0.3);
        }
    }

    &.btn-light {
        background: #fff;
        color: $color__theme;
        border: 2px solid #fff;
        padding: 14px 40px;

        &:focus {
            box-shadow: none;
            outline: 0;
        }

        &.color-two {
            color: $color__theme-2;
        }

        &:hover {
            background: transparent;
            color: #fff;
            border-color: #fff;
        }
    }
}

.btn-underline {
    color: #797687;
    font-size: 15px;
    position: relative;
    padding-bottom: 3px;
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: 15px;
    max-width: 140px;
    margin: 0 auto;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        background: #797687;
        transition: all 0.3s ease-in-out;
    }

    i {
        display: inline-block;
        margin-left: 10px;
    }

    &:hover {
        color: $color__theme;

        &:before {
            background: $color__theme;
        }
    }
}

.play-btn {
    display: inline-flex;
    align-items: center;
    color: #fff;
    font-weight: 600;

    i {
        height: 60px;
        width: 60px;
        text-align: center;
        line-height: 60px;
        color: $color__theme;
        background: #fff;
        border-radius: 50%;
        margin-right: 10px;
        font-size: 30px;
        text-shadow: 0px 10px 10px rgba(176, 70, 70, 0.4);
    }

    &:hover {
        color: #fff;
    }

    &-two {
        position: relative;      

        i {
            &:before {
                position: relative;
                z-index: 222;
            }
            &:after {
                position: absolute;
                content: '';
                height: 50px;
                width: 50px;
                background: #e6e7eb;
                border-radius: 50%;
                top: 5px;
                left: 5px;
                z-index: 1;
            }
        }
    }
}


  

.app-btn {
    padding: 10px 20px;
    
    border-radius: 30px;
    color: $color__theme-2;
    display: inline-block;
    font-size: 18px;
    border: 2px solid $color__theme-2;
    

    i {
        color: $color__theme-2;
        font-size: 20px;
        vertical-align: -2px;
        margin-right: 4px;
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        color: #fff;
        background: $color__theme-2;
        box-shadow: 0px 20px 30px 0px rgba(67, 37, 204, 0.2);

        i {
            color: #fff;
        }
    }

    &.btn-active {
        background: transparent;
        color: #fff;
        box-shadow: 0px 20px 30px 0px rgba(67, 37, 204, 0.2);
        background: $color__theme-2;

        i {
            color: #fff;
        }

        &:hover {
            background: transparent;
            color: $color__theme-2;
            box-shadow: none;

            i {
                color: $color__theme-2;
            }
        }
    }
}


/* Back To Top */
.return-to-top {
    position: fixed;
    bottom: -30px;
    right: 20px;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    cursor: pointer;
    z-index: 998;
    border-radius: 50%;
    opacity: 0;
    transition: bottom .5s ease, opacity .5s ease;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        opacity: 1;
        display: block;
        transform: scale(1);
        transition: all .3s ease;
        border-radius: inherit;
        transition: transform .5s ease, opacity .6s ease;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        background: rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 0px 0px 0px transparent;
        box-shadow: 0px 0px 0px 0px transparent;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        transition: all .3s ease;
        border-radius: inherit;
    }

    >i {
        position: relative;
        overflow: hidden;
        font-size: 12px;
        width: inherit;
        height: inherit;
        line-height: inherit;
        display: block;
        color: transparent;
        text-shadow: 0px 0px #fff, 0px 50px #fff;
        -webkit-transition: text-shadow .2s ease;
        -moz-transition: text-shadow .2s ease;
        transition: text-shadow .2s ease;
        z-index: 1;
    }

    &:hover {
        &:after {
            transform: scale(1.07);
            background: $color__theme;
            box-shadow: 0px 10px 20px 8px rgba(0, 0, 0, 0.15);
        }

        >i {
            text-shadow: 0px -50px #fff, 0px 0px #fff;

        }
    }

    &.back-top {
        bottom: 20px;
        opacity: 1;
    }
}
